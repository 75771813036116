import { useEffect, type FC, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';
import { styled } from '@mui/material/styles';

import type { NavColor } from '../../../types/settings';

import type { Section } from '../config';
import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import { SIDE_NAV_WIDTH } from '../../../store/layoutModel';
import { useStoreState, useStoreActions } from '../../../store';

const VerticalLayoutRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    marginTop: 100,
  })
);

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  // backgroundColor: '#f0f0f0',
  flexDirection: 'column',
  width: '100%'
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

const useSideNavOpen = () => {
  const isSideNavOpen = useStoreState((state: any) => state.layout.isSideNavOpen);
  const sideNavWidth = useStoreState((state: any) => state.layout.sideNavWidth);
  
  const toggleOpen = useStoreActions((actions: any) => actions.layout.toggleOpen);
  const setIsSideNavOpen = useStoreActions((actions: any) => actions.layout.setIsSideNavOpen);
  const setSideNavWidth = useStoreActions((actions: any) => actions.layout.setSideNavWidth);
  
  useEffect(() => {
    setSideNavWidth(isSideNavOpen ? SIDE_NAV_WIDTH : '40px')
  }, [isSideNavOpen])
  
  return {
    isSideNavOpen,
    setIsSideNavOpen,
    sideNavWidth,
    toggleOpen
  }
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();
  const sideNavOpen = useSideNavOpen();

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} sideNavOpen={sideNavOpen} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          sideNavOpen={sideNavOpen}
          />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot
        sx={{
          paddingLeft: sideNavOpen.sideNavWidth,
          transition: 'padding 700ms cubic-bezier(0.2,0,0,1) 0s',
          border: '1px solid #f0f0f0',
        }}
      >
        <VerticalLayoutContainer>
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
  sections: PropTypes.array
};
