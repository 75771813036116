import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface AuthModel {
    userData: Record<string, any> | null;
    isAuthenticated: boolean;
    setisAuthenticated: Action<AuthModel, boolean>;
    setUserData: Action<AuthModel, Record<string, any> | null>;
    logout: Action<AuthModel>;
    login: Thunk<AuthModel>;
}

const authModel: AuthModel = {
    userData: null,
    isAuthenticated: !!localStorage.getItem('isAuthenticated'),

    setUserData: action((state, payload) => {
        state.userData = payload;
    }),

    setisAuthenticated: action((state, payload) => {
        state.isAuthenticated = payload;
    }),

    login: thunk(async (actions, values: any) => {
        try {
            const response = await axios.post('/auth/login', { email: values.email, password: values.password }, {
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            localStorage.setItem("access_token_all", response.data['data'].access_token);
            localStorage.setItem("refresh_token_all", response.data['data'].refresh_token);
            localStorage.setItem('isAuthenticated', 'true');
            
            actions.setUserData(response.data['data'].user);

            actions.setisAuthenticated(true);
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }

    }),
    logout: action((state) => {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('access_token_all');
        localStorage.removeItem('refresh_token_all');
        state.isAuthenticated = false;
        state.userData = null;
    }),
};

export default authModel;
